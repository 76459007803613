<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="col-md-4 col-xl-2"
          placeholder="Cerca..."
      ></b-form-input>
      <b-button class="mb-3 ml-auto" variant="success" v-b-modal.editModal
                @click="newItem">
        + Crea Nuovo
      </b-button>
    </div>
    <b-table
        hover
        bordered
        :filter="filter"
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
    >
      <template #cell(Azioni)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="editItem(data.item)"
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
        <a
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item)"
        ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
        ref="edit-modal"
        id="editModal"
        size="xl"
        hide-header
        @ok="save"
        @cancel="close"
        ok-title="Salva"
        cancel-title="Chiudi"
    >
      <h4 class="mb-4">{{formTitle}}</h4>
      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Tag Name</h6>
          <b-form-input
              v-model="editedItem.description"
              placeholder="Inserisci tag"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>

export default {
  name: "ServiceList",
  components: {
  },
  data: () => ({
    count_checkbox_caregiver: 0,
    selected_tab_wizard: 0,
    checkbox_caregiver: false,
    loadingWizard: false,
    errorMsg: null,
    sortBy: "id",
    fields: [
      { key: "id", sortable: true },
      { key: "description", sortable: true, label: 'Tipologia' },
      "Azioni",
    ],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      description: "",
    },
    defaultItem: {
      id: "",
      name: "",
      description: "",
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      const res = await this.axios.get('type-of-vehicles')
      await this.$store.dispatch("setDataTable", res.data)
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.$refs["edit-modal"].show();
      const that = this
      setTimeout(function () {
        that.$refs.serviceTag.value = item.available_services.split(',')
      }, 400)
    },

    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Tipologia di veicolo',
        text: 'Sei sicuro di voler eliminare '+item.description+'?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('type-of-vehicle/'+item.id)
          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Tag',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      const res = await this.axios.post('type-of-vehicle', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Tipo di veicolo',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
      await this.initialize();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuova Tipologia Veicolo" : "Modifica Tipologia Veicolo";
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    }
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>